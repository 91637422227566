<template>
    <b-modal 
        id="bv-clinic-report-modal"
        centered
        hide-header
        hide-footer
        size="xl"
        @show="onShow"
    >
        
        <div class="title">Triagem</div>
        <ScreeningResume 
            :loading="loading" 
            :screening="data?.screening?.response"
        />

        <div class="title">Teste de acuidade visual</div>
        <!-- <div v-if="!loading"  class="time">Realizado em {{ parseDate(data?.visualAcuity?.updatedAt) }}</div> -->
        <!-- <div v-else class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div> -->

        <div class="subtitle">Resultados</div>
        <div v-if="!loading" >Olho direito: <b>{{ data?.visualAcuity?.response?.right?.acuity }}</b></div>
        <div v-else class="skeleton"><b-skeleton animation="fade" width="200px"></b-skeleton></div>

        <div v-if="!loading" >Olho esquerdo: <b>{{ data?.visualAcuity?.response?.left?.acuity }}</b></div>
        <div v-else class="skeleton"><b-skeleton animation="fade" width="200px"></b-skeleton></div>

        <IshiharaResume 
            :loading="loading" 
            :ishihara="data?.ishihara?.response" 
        />

        <div v-if="data?.telemedicineExams?.length">
            <div class="title">Exames a serem realizados na consulta</div>
            <div v-for="exam in data?.telemedicineExams" :key="exam.id">
                <div>{{ exam.name }}</div>
            </div>
        </div>
    </b-modal>
</template>
<script>
    export default {
        components: {
            ScreeningResume: () => import('@/components/Attendance/Forms/Components/ScreeningResume'),
            IshiharaResume: () => import('@/components/Attendance/Forms/Components/IshiharaResume'),
        },
        props: {
            patientId: String,
        },
        data() {
            return {
                loading: false,
                data: null
            }
        },
        methods: {
            async onShow() {
                if (!this.patientId) return
                this.loading = true
                try {
                    const { data } = await this.api.getBVClinicalRecord(this.patientId)
                    this.data = data
                    this.loading = false
                } catch (err) {
                    this.$toast.error(err.message)
                } finally {
                    this.loading = false
                }
            },
            parseDate(date) {
                if (!date || !this.moment(date).isValid()) return '-'
                return this.moment(date).format('DD [de] MMMM [de] YYYY [às] HH:mm')
            },
        }
    }
</script>
<style lang="scss" scoped>
.name {
    color: var(--dark-blue, #0C1D59);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.title {
    margin-top: 2rem;
    color: var(--dark-blue, #0C1D59);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.subtitle {
    color: var(--type-active, #525C7A);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-top: 0.6rem;
}
.inline {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: .5rem;
    text-align: left;

    b {
        font-weight: 700;
        padding-left: 5px;
    }
}
.time {
    color: var(--blue-500, #305BF2);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
</style>